.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-masthead {
  width: 100%;
  pointer-events: none;
}

.App-intro {
  margin: auto;
  display: block;
  height: 50vh;
}

.App-sample {
  margin: auto;
  display: block;
  width: 50vw;
}

.App-printing-logo {
  margin: auto;
  display: block;
  height: 25vh;
}

.App-center {
  margin: auto;
  display: block;
}

.App-story {  
  font-size: 2em !important;
  margin: auto;
  height: auto !important;
  line-height: 1.5em !important;
  padding: 0 !important;
  color: #333333 !important;
  font-family: proxima-nova, Helvetica, Arial, sans-serif !important;
}

.App-contact {
  width: 100%;
  height: 1024px;  
  overflow: hidden;
}

.App-contact-bullets {
  font-family:times new roman,times,serif;
}

.App-text-center {
  text-align: center;
}

.flipbook-embed {
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-img-200-x-200 {
  width: 200px;
  height: 200px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
